
import { ICoursePlan } from '@/models/academic-definitions/interfaces/CoursePlan'
import { defineComponent, onBeforeMount, onMounted, PropType, ref, watch } from 'vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import TutorsActionSelector from '@/views/education/staff/components/TutorsActionSelector.vue'
import TutorsTable from '@/views/education/staff/components/TutorsTable.vue'
import CoursePlanSelectorCustom from '@/views/education/staff/components/CoursePlanSelectorCustom.vue'
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { StaffTracingAPI } from '@/api/staffTracing.api'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import router from '@/router'
import { CommonRouteNames } from '@/router/route-names'
import { ColumnFields, tracingActions } from '@/models/components/tables/enums'
import OverlayPanel from 'primevue/overlaypanel'
import ScrollPanel from 'primevue/scrollpanel'
import { Column } from '@/models/components/tables/Column'
import { CustomFilter, FilterValue } from '@/models/custom-filters/classes/CustomFilter'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useToast } from 'primevue/usetoast'
import { CustomFiltersAPI } from '@/api/custom-filters.api'
import { ToastSeverities, ToastSummaries, ToastLife } from '@/models/components/toast/enums'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import Chip from 'primevue/chip'

export default defineComponent({
  components: {
    TutorsActionSelector,
    TutorsTable,
    CoursePlanSelectorCustom,
    OverlayPanel,
    ScrollPanel,
    Chip
  },
  props: {
    allCoursePlans: {
      type: Array as PropType<Array<ICoursePlan>>,
      required: true
    },
    role: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const nameFilter = ref<string>('')
    const actionFilter = ref<number>(-1)
    const coursesFilter = ref<CoursePlan[]>([])
    const staffTracing = ref<StaffTracingResponse>(new StaffTracingResponse(0, 0, []))
    const isLoading = ref(false)
    const errorRef = ref<null|unknown>(null)
    const currentPage = ref(0)
    const showDialog = ref(false)
    const filterNameInput = ref<string>('')
    const user = useUserSessionStore().currentUser
    const toast = useToast()
    const allCustomViews = ref<CustomFilter[]>([])
    const viewSelected = ref<CustomFilter|null>(null)
    const searchTxt$ = new Observable<string>(subscriber => {
      watch(nameFilter, () => subscriber.next(nameFilter.value))
    })
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'RTG Semanal', field: ColumnFields.TracingType },
      { header: 'Tags', field: ColumnFields.TracingStage },
      { header: 'Accion', field: ColumnFields.TracingRating }
    ]
    const defaultCol = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'RTG Semanal', 'Tags', 'Accion'])
    const showCol = ref<string[]>(['Nombre', 'Curso', 'Comisión', 'RTG Semanal', 'Tags', 'Accion'])
    const op = ref()

    const removeSelectedView = () => {
      viewSelected.value = null
      showCol.value = defaultCol.value
    }

    const selectView = (value: CustomFilter) => {
      viewSelected.value = value
      showCol.value = viewSelected.value.filterProps.columns.value as string[]
    }

    const showDialogOpen = () => {
      showDialog.value = true
    }

    const showDialogClose = () => {
      showDialog.value = false
    }

    const getFilterView = async () => {
      try {
        const cf = await CustomFiltersAPI.findAllByTableName(user._id, TableName.TutorsView)
        allCustomViews.value = cf.find(type => type._id === TypeFilter.View)?.data as CustomFilter[] || []
      } catch (e) {
        console.error(e)
      }
    }

    const deleteFilter = async (cf: CustomFilter): Promise<void> => {
      try {
        await CustomFiltersAPI.delete(cf._id as string)
        toast.add({
          severity: ToastSeverities.Success,
          summary: ToastSummaries.Success,
          detail: 'Filtro eliminado',
          life: ToastLife.Default
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: 'Error al eliminar filtro',
          life: ToastLife.Default
        })
      } finally {
        await getFilterView()
        removeSelectedView()
      }
    }

    const saveFilterView = async () => {
      const isFilterType = TypeFilter.View
      const filterProps: Record<string, FilterValue<unknown>> = {
        columns: {
          value: showCol.value
        }
      }
      const customFilter = new CustomFilter(
        filterProps,
        user._id,
        isFilterType,
        TableName.TutorsView,
        filterNameInput.value,
        false // si hay un filtro guardado preseleccionado, lo que va a hacer es sobreescribirlo
      )
      try {
        await CustomFiltersAPI.upsert(customFilter)
        showDialogClose()
        toast.add({
          severity: ToastSeverities.Success,
          summary: ToastSummaries.Success,
          detail: 'Vista guardada',
          life: ToastLife.Default
        })
      } catch (error) {
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: isFilterType ? 'Error al crear filtro' : 'Error al crear la vista',
          life: ToastLife.Default
        })
      } finally {
        getFilterView()
      }
    }

    const toggle = (event: Event) => {
      op.value.toggle(event)
    }
    const clickCheck = (selectedCheck: any) => {
      if (showCol.value.find(element => element === selectedCheck.key)) {
        showCol.value = []
      }
      showCol.value.push(selectedCheck)
    }
    const myOptions: StateOnboardingFilter[] = [
      {
        name: tracingActions.ACTUATE, id: 0
      },
      {
        name: tracingActions.CALL, id: 1
      },
      {
        name: tracingActions.MATERIAL, id: 2
      },
      {
        name: tracingActions.APPROACH, id: 3
      },
      {
        name: tracingActions.IMPROVED, id: 4
      },
      {
        name: tracingActions.REPEATED, id: 5
      },
      {
        name: tracingActions.FIRST_TIME, id: 6
      },
      {
        name: tracingActions.FEEDBACK, id: 7
      },
      {
        name: tracingActions.ADVISORY, id: 8
      }
    ]
    const loadTracing = async (page: number) => {
      try {
        isLoading.value = true
        // staffTracing.value = new StaffTracingResponse(0, 0, [])
        const response: StaffTracingResponse = await StaffTracingAPI.getStaffTracing({
          limit: 20,
          page: page + 1,
          person: nameFilter.value,
          role: 'tutor',
          name: nameFilter.value,
          tutorsType: props.role,
          courses: coursesFilter.value.map((course) => course.information.name),
          tutorsAction: actionFilter.value === -1 ? '' : myOptions.find((op) => op.id === actionFilter.value)!.name
        })
        response.data.forEach(element => {
          staffTracing.value.data.push(element)
        })
        currentPage.value = currentPage.value + 1
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    }
    const applyFilter = () => {
      reset()
      loadTracing(currentPage.value)
    }
    const reset = () => {
      currentPage.value = 0
      staffTracing.value.data = []
    }

    const onScroll = (event: any) => {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        loadTracing(currentPage.value)
      }
    }

    const onPage = async (event: any) => {
      loadTracing(event.page)
    }
    onBeforeMount(() => {
      isLoading.value = true
    })
    onMounted(async () => {
      loadTracing(currentPage.value)
      getFilterView()
    })
    watch(nameFilter, () => {
      searchTxt$.pipe(
        debounceTime(800),
        distinctUntilChanged()
      ).subscribe(() => {
        applyFilter()
      })
    })
    watch(actionFilter, () => {
      // name filter here
      applyFilter()
    })
    return {
      nameFilter,
      actionFilter,
      coursesFilter,
      staffTracing,
      isLoading,
      loadTracing,
      onPage,
      onScroll,
      applyFilter,
      toggle,
      op,
      columns,
      clickCheck,
      showCol,
      showDialog,
      filterNameInput,
      showDialogClose,
      showDialogOpen,
      saveFilterView,
      allCustomViews,
      deleteFilter,
      viewSelected,
      removeSelectedView,
      selectView
    }
  }
})
