
import { tracingActions } from '@/models/components/tables/enums'
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { WarmUpStage } from '@/models/tracing-staff/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    const myOptions: StateOnboardingFilter[] = [
      {
        name: 'Todos', id: -1
      },
      {
        name: tracingActions.ACTUATE, id: 0
      },
      {
        name: tracingActions.CALL, id: 1
      },
      {
        name: tracingActions.MATERIAL, id: 2
      },
      {
        name: tracingActions.APPROACH, id: 3
      },
      {
        name: tracingActions.IMPROVED, id: 4
      },
      {
        name: tracingActions.REPEATED, id: 5
      },
      {
        name: tracingActions.FIRST_TIME, id: 6
      },
      {
        name: tracingActions.FEEDBACK, id: 7
      },
      {
        name: tracingActions.ADVISORY, id: 8
      }
    ]
    return {
      myOptions,
      optionSelected: ''
    }
  }
})
