import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.optionSelected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.optionSelected = $event)),
    options: _ctx.myOptions,
    optionLabel: "name",
    optionValue: "id",
    placeholder: "Estado"
  }, null, 8, ["modelValue", "options"]))
}