
import { StaffTracingAPI } from '@/api/staffTracing.api'
import { useToastService } from '@/hooks/useToastService'
import { tracingActions } from '@/models/components/tables/enums'
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { WarmUpStage } from '@/models/tracing-staff/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    staffTracing: {
      type: StaffTracing,
      required: true
    }
  },
  data (props) {
    const toast = useToastService()
    const changeState = async (e: any) => {
      try {
        const info: any = { ...props.staffTracing }
        delete info.userInfo
        info.tracingActions = e.value
        await StaffTracingAPI.updateTracing(info)
      } catch (error) {
        toast.showError('Error al actualizar Acción')
      }
    }

    const myOptions = [
      {
        name: 'ACCIONAR', code: tracingActions.ACTUATE
      },
      {
        name: 'Call', code: tracingActions.CALL
      },
      {
        name: 'Material', code: tracingActions.MATERIAL
      },
      {
        name: 'Acercamiento', code: tracingActions.APPROACH
      },
      {
        name: 'Mejoró', code: tracingActions.IMPROVED
      },
      {
        name: 'Se repite', code: tracingActions.REPEATED
      },
      {
        name: 'Primera Vez', code: tracingActions.FIRST_TIME
      },
      {
        name: 'Feedback', code: tracingActions.FEEDBACK
      },
      {
        name: 'Asesoría', code: tracingActions.ADVISORY
      }
    ]
    return {
      myOptions,
      optionSelected: props.staffTracing.tracingActions,
      changeState
    }
  }
})
